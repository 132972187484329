import * as React from 'react'
import { useRef } from 'react'
import Marquee from 'react-fast-marquee'
import { Swiper, SwiperSlide } from 'swiper/react'
import ImageComponent from './CaseStudyImageComponent'

interface DetailSlider_cardProps {
  cardTitle?: string
  cardDescription?: string
  sliderImages?: string[]
  ideaVideoURL?: string
  ideaVideo?: string
  ideaVideoImage?: string
  type: string
}

function DetailSlider_card(data: DetailSlider_cardProps) {
  return (
    <>
      {data.cardTitle && (
        <h2
          id={data.cardTitle}
          className="secondary-font font-normal text-[45px] md:text-[140px] text-[#F9FAF4] -tracking-[0.03em] leading-[53px] md:leading-[164px] px-[12px] md:px-[28px] pt-[30px] md:pt-[60px] pb-[25px] md:pb-[30px]">
          {data.cardTitle}
        </h2>
      )}
      {data.cardDescription && (
        <p className="font-medium text-[28px] md:text-[45px] text-[#fff] tracking-[0.01em] leading-[40px] md:leading-[55px] px-[12px] md:px-[28px] pt-[30px] pb-[45px]">
          {data.cardDescription}
        </p>
      )}
      {data.sliderImages && (
        <div className="swiper-mouse">
          <Swiper
            slidesPerView={1.5}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 30
              },
              768: {
                slidesPerView: 1.5,
                spaceBetween: 30
              }
            }}
            spaceBetween={30}
            pagination={{
              clickable: true
            }}
            className="mySwiper">
            {data.sliderImages.map((slide: string) => (
              // <div
              //   className="text-[17px] md:text-[30px] secondary-font uppercase text-primary border border-[#DDFF8D] px-4 m-2 rounded-full font-normal"
              //   key={data.type + slide}>
              <SwiperSlide
                className="border-r border-[#333549] px-[12px] md:px-[0] p-10 h-full my-auto"
                key={`slide` + data.type + slide}>
                <ImageComponent
                  imageName={slide}
                  caseStudyImage="max-h-[600px] min-h-[300px] md:min-h-[600px] object-contain"
                />
              </SwiperSlide>
              // </div>
            ))}
          </Swiper>
        </div>
      )}
      {data.ideaVideoURL && (
        <div className="md:py-[100px] py-[30px]">
          <div className=" w-[310px] h-[230px] md:w-[790px] md:h-[590px] lg:w-[970px] lg:h-[730px] mx-auto p-3 overflow-clip bg-[url('../images/ipad-frame.png')] bg-no-repeat bg-[length:100%_100%]">
            <iframe
              src={data.ideaVideoURL}
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
              className="w-full h-full rounded-[8px] md:rounded-[30px]"
            />
          </div>
        </div>
      )}
      {data.ideaVideo && (
        <div className="md:py-[100px] py-[30px]">
          <div className="w-[310px] h-[230px] md:w-[790px] md:h-[590px] lg:w-[970px] lg:h-[730px] mx-auto p-2 md:p-5 overflow-clip bg-[url('../images/ipad-frame.png')] bg-no-repeat bg-[length:100%_100%]">
            <video
              className="object-cover h-full w-full rounded-[12px] lg:rounded-[22px]"
              autoPlay={true}
              muted={true}
              playsInline>
              <source src={data.ideaVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
      {data.ideaVideoImage && (
        <div className="text-center md:py-[175px] py-[30px] px-[15px]">
          <ImageComponent imageName={data.ideaVideoImage} caseStudyImage="" />
        </div>
      )}
    </>
  )
}
export default DetailSlider_card
