import * as React from 'react'
import ImageComponent from './CaseStudyImageComponent'
import Tilt from 'react-parallax-tilt'

interface Mobile_cardProps {
  ctoLayer?: string
  ctoImage?: string
  ctoTitle?: string
  ctoText?: string
}

function Mobile_card(data: Mobile_cardProps) {
  return (
    <>
      {data.ctoLayer && data.ctoImage && (
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="items-center text-center  md:py-40 md:hidden border-b md:border-b-0 border-[#333549]">
            <Tilt className="w-full h-[500px] sm:h-[700px]  layerd-tilt flex items-center justify-center">
              <ImageComponent
                imageName={data.ctoLayer}
                caseStudyImage="!absolute  max-w-[295px] sm:max-w-[445px] layer-1"
              />
              <ImageComponent
                imageName={data.ctoImage}
                caseStudyImage="!absolute  max-w-[295px] sm:max-w-[445px]  layer-3"
              />
            </Tilt>
          </div>

          {data.ctoText && (
            <div className="md:border-r border-[#333549] px-[12px] py-[50px] md:p-6 flex flex-col justify-center">
              <h3 className="font-semibold text-[28px] md:text-[32px] text-[#DDFF8D] w-full">
                {data.ctoTitle}
              </h3>
              <p className="font-medium text-[28px] md:text-[45px] text-[#fff] tracking-[0.01em] leading-[40px] md:leading-[55px]">
                {data.ctoText}
              </p>
            </div>
          )}

          <div className="items-center text-center  md:py-40 hidden md:flex">
            <Tilt className="w-full h-[500px] sm:h-[700px] layerd-tilt flex items-center justify-center">
              <ImageComponent
                imageName={data.ctoLayer}
                caseStudyImage="!absolute  max-w-[295px] sm:max-w-[445px] layer-1"
              />
              <ImageComponent
                imageName={data.ctoImage}
                caseStudyImage="!absolute  max-w-[295px] sm:max-w-[445px]  layer-3"
              />
            </Tilt>
          </div>
        </div>
      )}
    </>
  )
}
export default Mobile_card
