import * as React from 'react'
import Marquee from 'react-fast-marquee'
import Tilt from 'react-parallax-tilt'
import { Swiper, SwiperSlide } from 'swiper/react'
import Contact_us from '../ContactUs'
import Header from '../layout/Navbar'
import Footer from '../layout/Footer'
import Content_Container from '../../layout/Content_Container'
import ImageComponent from '../caseStudies/CaseStudyImageComponent'
import { Link } from 'gatsby'
import { caseStudiesType } from '../../types/case-studies'

import Marquee_card from '../caseStudies/MarqueeCard'
import MoreProject_card from '../caseStudies/MoreProjectCard'
import Detail_card from '../caseStudies/DetailCard'
import Mobile_card from '../caseStudies/MobileCard'
import Quote_card from '../caseStudies/QuoteCard'

interface Marquee_cardProps {
  pageContext: {
    study: caseStudiesType
  }
}

function CaseStudy_template(props: Marquee_cardProps) {
  const black = true
  const visible = false
  const navColor = 'black'

  return (
    <Content_Container background={black ? 'bg-[#151315]' : 'bg-[#f1f3e5]'}>
      <Header visible={visible} navColor={navColor} />

      <div className="max-w-[calc(100vw-26px)] lg:max-w-[1392px] mx-auto">
        <div className="border border-[#333549] divide-y divide-[#333549]">
          {/* title */}
          <h1 className="secondary-font font-normal text-[45px] md:text-[140px] text-[#F9FAF4] -tracking-[0.03em] leading-[53px] md:leading-[164px] px-[12px] md:px-[28px] pt-[30px] md:pt-[30px] pb-[25px] md:pb-[0]">
            {props.pageContext.study.name}
          </h1>
          {/* hero image */}
          <div className="p-3 md:p-5 hidden md:block">
            {props.pageContext &&
              props.pageContext.study &&
              props.pageContext.study.topImage && (
                <ImageComponent
                  imageName={props.pageContext.study.topImage}
                  caseStudyImage="w-full"
                />
              )}
          </div>
          <div className="p-3 md:p-5 md:hidden">
            {props.pageContext &&
              props.pageContext.study &&
              props.pageContext.study.topImage && (
                <ImageComponent
                  imageName={props.pageContext.study.topImageMobile}
                  caseStudyImage="w-full"
                />
              )}
          </div>

          <Marquee_card
            features={props.pageContext.study.features}></Marquee_card>

          {/* About */}
          <Detail_card
            sliderImages={props.pageContext.study.projectImages}
            cardTitle={props.pageContext.study.aboutTitle}
            cardDescription={props.pageContext.study.aboutDescription}
            type="about"></Detail_card>

          {/* Idea */}
          <Detail_card
            cardTitle={props.pageContext.study.ideaTitle}
            cardDescription={props.pageContext.study.ideaDescription}
            ideaVideoURL={props.pageContext.study.ideaVideoURL}
            ideaVideoImage={props.pageContext.study.ideaVideoImage1}
            ideaVideo={props.pageContext.study.ideaVideo}
            type="idea"></Detail_card>

          <Marquee_card
            features={props.pageContext.study.features}></Marquee_card>

          {/* Execution */}
          <Mobile_card
            executionTitle={props.pageContext.study.executionTitle}
            mobileImage={props.pageContext.study.mobileImage}
            mobileDescription={props.pageContext.study.mobileDescription}
            projectUrl={props.pageContext.study.projectUrl}></Mobile_card>

          <Quote_card
            ctoLayer={props.pageContext.study.ctoLayer}
            ctoImage={props.pageContext.study.ctoImage}
            ctoTitle={props.pageContext.study.ctoTitle}
            ctoText={props.pageContext.study.ctoText}></Quote_card>

          {/* Lab */}
          <Detail_card
            sliderImages={props.pageContext.study.labImages}
            cardTitle={props.pageContext.study.labsTitle}
            cardDescription={props.pageContext.study.labsDescription}
            type="lab"></Detail_card>

          {props.pageContext &&
            props.pageContext.study &&
            props.pageContext.study.labsDescription && (
              <Marquee_card
                features={props.pageContext.study.features}></Marquee_card>
            )}

          {/* Idea 2*/}
          <Detail_card
            cardTitle={props.pageContext.study.ideaTitle2}
            cardDescription={props.pageContext.study.ideaDescription2}
            ideaVideoImage={props.pageContext.study.ideaVideoImage2}
            type="idea2"></Detail_card>

          {/* Execution 2*/}
          <Mobile_card
            executionTitle={props.pageContext.study.executionTitle2}
            mobileImage={props.pageContext.study.mobileImage2}
            mobileDescription={props.pageContext.study.mobileDescription2}
            projectUrl={props.pageContext.study.projectUrl}></Mobile_card>

          <Quote_card
            ctoLayer={props.pageContext.study.ctoLayer2}
            ctoImage={props.pageContext.study.ctoImage2}
            ctoTitle={props.pageContext.study.ctoTitle2}
            ctoText={props.pageContext.study.ctoText2}></Quote_card>

          {/* More Project */}
          <MoreProject_card name={props.pageContext.study.slug} />
        </div>
      </div>

      <Contact_us background={black ? 'bg-[#141414]' : 'bg-blue'} />
      <Footer background={black ? 'bg-[#141414]' : 'bg-blue'} />
    </Content_Container>
  )
}
export default CaseStudy_template
