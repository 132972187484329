import * as React from 'react'
import Marquee from 'react-fast-marquee'

interface Marquee_cardProps {
  features: string[]
}

function Marquee_card(data: Marquee_cardProps) {
  return (
    <div className="py-5">
      <Marquee gradient={false}>
        {data.features.map((slide: string) => (
          <div
            className="text-[17px] md:text-[30px] secondary-font uppercase text-primary border-2 leading-[24px] md:leading-[37px] border-[#424A30] px-4 m-2 rounded-full font-normal pt-1"
            key={slide}>
            {slide}
          </div>
        ))}
      </Marquee>
    </div>
  )
}
export default Marquee_card
