import * as React from 'react'
import ImageComponent from './CaseStudyImageComponent'

interface Mobile_cardProps {
  executionTitle?: string
  mobileImage?: string
  mobileDescription?: string
  projectUrl?: string
}

function Mobile_card(data: Mobile_cardProps) {
  return (
    <>
      {data.executionTitle && (
        <h2
          className="secondary-font font-normal text-[45px] md:text-[100px] lg:text-[140px] 
        text-[#F9FAF4] -tracking-[0.03em] leading-[53px] md:leading-[164px] 
        px-[12px] md:px-[28px] pt-[30px] md:pt-[60px] pb-[25px] md:pb-[30px]">
          {data.executionTitle}
        </h2>
      )}
      {data.mobileDescription && data.mobileImage && (
        <div className="grid grid-cols-1 md:grid-cols-2 items-center ">
          <div className="items-center border-b md:border-b-0  md:border-r border-[#333549] text-center py-[50px] md:py-[130px] px-[50px] h-full flex justify-center">
            <ImageComponent imageName={data.mobileImage} caseStudyImage="" />
          </div>
          <div className="px-[12px] md:px-[28px] py-[30px] ">
            <div
              className="execution-data font-medium text-[28px] md:text-[45px] text-[#fff] tracking-[0.01em] leading-[40px] md:leading-[55px]"
              dangerouslySetInnerHTML={{
                __html: data.mobileDescription
              }}></div>
            <a
              className="primary-btn !bg-primary !text-blue mb-[20px] !mt-[60px] md:!mt-[80px] md:mb-[0]"
              target="_blank"
              rel="noreferrer"
              href={data.projectUrl}>
              Learn more
            </a>
          </div>
        </div>
      )}
    </>
  )
}
export default Mobile_card
