import * as React from 'react'
import { Link } from 'gatsby'

function MoreProject({ name }: { name: string }) {
  const links = [
    { title: 'Alkimi Exchange', path: '/case-studies/alkimi', name: 'alkimi' },
    { title: 'Affiniti', path: '/case-studies/affiniti', name: 'affiniti' },
    {
      title: 'Alex The Alkimist',
      path: '/case-studies/alex-the-alkimist',
      name: 'alex-the-alkimist'
    },
    { title: 'NokPay', path: '/case-studies/nok-pay', name: 'nok-pay' },
    { title: 'Jenny Co', path: '/case-studies/jenny-co', name: 'jenny-co' }
  ]
  const getVisibility = ({ path }: { path: string }) => {
    if (path.split('/')[2] === name) return false
    return true
  }

  return (
    <>
      <h2 className="secondary-font font-normal text-[45px] md:text-[140px] text-[#F9FAF4] -tracking-[0.03em] leading-[55px] md:leading-[164px] pt-9 pb-6 md:pt-10 md:pb-5 px-5 text-center md:text-left">
        More
        <br />
        projects
      </h2>
      <div>
        {links.filter(getVisibility).map((link) => (
          <Link
            className={`font-medium text-[20px] tracking-[0.01em] text-[#F9FAF4] group-hover:text-[#DDFF8D]  `}
            key={link.name}
            to={link.path}>
            <div className="flex flex-col md:flex-row items-center justify-between group p-5 pr-10 border-b border-[#333549]">
              <h3 className="font-medium text-[50px] md:text-[128px] text-[#F9FAF4] group-hover:text-[#DDFF8D] -tracking-[0.03em]">
                {link.title}
              </h3>
              <span className="font-medium text-[20px] tracking-[0.01em] text-[#F9FAF4] group-hover:text-[#DDFF8D] underline lg:mt-16">
                Learn more
              </span>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}
export default MoreProject
